import React from 'react'
import './CardStyles.css'
import { BsBoxArrowInUpRight } from "react-icons/bs";

const Card = (props) => {

  const {title} = props;
  const {photo} = props;
  const {button} = props;
  const {info} = props;
  return (
    <div className='card'>
        <div className="container-card">
          
          
          <img className={photo === 'none'? 'hide': 'card-img'} src={photo} alt="" />
          <h2 className='card-title'>{title}</h2>

          <p className='card-description'>{info}</p>
          <div className="button">
            <a href="https://leetcode.com/u/DaviPaiva/" target='_blank' rel="noreferrer"><button className={button === 'none'? 'hide':'about-button'}><p>{button}</p><BsBoxArrowInUpRight className='card-icon' size='18'/></button></a>
          </div>
          
            
            
            {/* <div className="details">
              <h2 className='card-title'>Test</h2>
              <p className='card-desscription'>hi</p>
              <button className="card-button">See more</button>
            </div> */}
            
        </div>
    </div>
  )
}

export default Card